import React, { memo, useState } from "react";
import moment from "moment";
import { Date_Time_Format } from "../../../../../config";
import { CustomGrid } from "../../../../CustomTable";
const CallLogs = (props) => {
  const { loading, allCallLogs, t } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(10);

  const secondsToMinutes = (params) => {
    return params.value
      ? new Date(params.value * 1000).toISOString().slice(14, 19)
      : "";
  };

  const formateCell = (params) => {
    return params.value ? params.value : "-";
  };

  const formateCallDateTime = (params) => {
    
    return params.value ? moment(params.value).format(Date_Time_Format) : "-";
  };

  const onChangeRecords = (page) => {
    setCurrentPage(page.page);
    setPerPageCount(page.pageSize);
  };

  const columns = [
    {
      headerName: `${t("Irn")}`,
      field: "interviewReferenceNumber",
      renderCell: formateCell,
      flex: 1,
      sortable: false,
      cellClassName: "data_grid_table_cell",
    },
    {
      headerName: `${t("Call Date Time")}`,
      field: "callDateTime",
      renderCell: formateCallDateTime,
      flex: 1.5,
      sortable: false,
      cellClassName: "data_grid_table_cell",
    },
    {
      headerName: `${t("Access Code")}`,
      field: "accessCode",
      renderCell: formateCell,
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Number Dialed")}`,
      field: "dialInNumber",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Call Duration")}`,
      field: "durationSeconds",
      renderCell: secondsToMinutes,
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("DTMFS")}`,
      field: "dtmfs",
      renderCell: formateCell,
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Telco")}`,
      field: "providerName",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Caller Id")}`,
      field: "callerNumber",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Destination #")}`,
      field: "destinationNumber",
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <div className="table_wrapper participant-table app-table participant-detail-modal-table custom_table_container">
      <CustomGrid
        data={allCallLogs}
        getRowId={(row) => row.id}
        columns={columns}
        loading={loading}
        currentPage={currentPage}
        perPageCount={perPageCount}
        totalPages={allCallLogs.length}
        onChangeRecords={onChangeRecords}
        isServerPagination={false}
      />
    </div>
  );
};
export default memo(CallLogs);
