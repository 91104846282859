import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Routes from "../Routes";
import { themeActions } from "../store/actions/ThemeAction";
import { authActions } from "../store/actions/authAction";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import "./App.css";
import "./dark_theme.scss";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-lAoW2SBhBM0Xno8",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

const App = (props) => {
  const [theme, setTheme] = useState("dark_theme");

  useEffect(() => {
    if (localStorage.getItem("theme_preference") !== null) {
      const getThemePreference = localStorage.getItem("theme_preference");
      props.getTheme(getThemePreference);
      if (getThemePreference === "dark") {
        document.body.classList.add("dark_theme");
      }
    } else {
      props.getTheme("light");
      document.body.className = document.body.className.replace(
        "dark_theme",
        ""
      );
    }
    (props.themeColor === "light") ? setTheme("light_theme") : setTheme("dark_theme");
  }, [props.themeColor]);

  useEffect(() => {
    props.setAuthorization(false);
    // Load features asynchronously when the app renders
    growthbook.init();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>  
      <div className={`${theme}`}>
        <Routes />
      </div>
    </GrowthBookProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
const mapDispatchToProps = {
  getTheme: themeActions.getTheme,
  setAuthorization: authActions.setAuthorization,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
