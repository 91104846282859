import React from "react";
import { Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

export const EmailHelperBox = ({
  isPopoverOpen,
  anchorEl,
  onClose,
  commonPlaceholders = [],
  bodyPlaceholders = [],
}) => {
  const { t } = useTranslation();

  const renderPlaceholders = (placeholders) =>
    placeholders.map((placeholder, index) => (
      <span key={index}>
        {placeholder.placeholder}
        {index < placeholders.length - 1 && ", "}
      </span>
    ));

  return (
    <Popover
      id="click-popover"
      open={isPopoverOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="helper-box">
        <div className="mb-3">
          <div className="placeholder-heading">
            {t("COMMON_PLACEHOLDERS")}{" "}
            <span className="light-heading">
              ({t("CAN_BE_USED_IN_EMAIL_SUBJECT_AND_BODY_BOTH")})
            </span>
          </div>
          <div className="placeholder-label">
            {renderPlaceholders(commonPlaceholders)}
          </div>
        </div>
        <div>
          <div className="placeholder-heading">
            {t("EMAIL_BODY_ONLY_PLACEHOLDERS")}
          </div>
          <div className="placeholder-label">
            {renderPlaceholders(bodyPlaceholders)}
          </div>
        </div>
      </div>
    </Popover>
  );
};
