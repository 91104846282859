import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import { Card, CardContent, Grid2 as Grid, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as Yup from "yup";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../../components/Common";
import { scrollTopWindow } from "../../../../Helpers/index";
import { SendEmail } from "../../../../Services/participantsService";
import {
  getCommunicationDetail,
  getProjectDetail,
  updateCommunicationDetail,
} from "../../../../Services/projectServices";
import CustomPrompt from "../../../Utility/CustomPrompt/CustomPrompt";
import { stringExistsInArray } from "../../../Utility/userPermission";
import { EmailHelperBox } from "./EmailHelperBox";
class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: "",
      emailData: "",
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      value: "",
      loading: false,
      loadingEmailData: true,
      disablesavebtnme: false,
      initialValuesTemplateType: "TEST_INVITATION_EMAIL",
      templateTypeValue: {
        value: "TEST_INVITATION_EMAIL",
        label: "Invitation Template",
      },
      projectLanguagesData: [],
      selectedLanguage: null,
      languageId: "",
      langEditorState: "",
      emailSubjectPlaceholders: [],
      emailBodyPlaceholders: [],
      anchorEl: null,
    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.id;
    getProjectDetail(projectId)
      .then((res) => {
        this.setState(
          {
            projectLanguagesData: res.data.projectLanguagesData || [],
            languageId:
              res.data.projectLanguagesData.length > 0
                ? res.data.projectLanguagesData[0].value
                : "",
            projectId: projectId,
          },
          () => {
            const languageId = this.state.languageId;
            this.getCommunicationData(
              projectId,
              "TEST_INVITATION_EMAIL",
              languageId
            );
          }
        );
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          this.setState({
            alertMsg: `${err.response?.data?.message}`,
            alertStatus: true,
            variant: "danger",
          });
        }
      });
  }

  /**
   * This function will give you a communication detail
   * @param {string} projectId
   * @param {string} templateType
   * @param {stirng} languageId
   */
  getCommunicationData = (projectId, templateType, languageId) => {
    getCommunicationDetail(projectId, templateType, languageId)
      .then((res) => {
        const getPlaceholders = res?.data?.placeholders || [];
        const extractEmailSubjectPlaceholders =
          getPlaceholders &&
          getPlaceholders
            .filter((value) => value.isDefaultPlaceholder === true)
            .map((value) => value.placeholder.replace(/{{|}}/g, ""));
        const extractEmailBodyPlaceholders =
          getPlaceholders &&
          getPlaceholders
            .filter((value) => value.isDefaultPlaceholder === false)
            .map((value) => value.placeholder.replace(/{{|}}/g, ""));

        const contentBlock = htmlToDraft(res.data.template_content || "");
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState(
            {
              editorState,
              emailData: res.data,
              emailSubjectPlaceholders: extractEmailSubjectPlaceholders,
              emailBodyPlaceholders: [
                ...extractEmailBodyPlaceholders,
                ...extractEmailSubjectPlaceholders,
              ],
            },
            () => {
              const emailtext =
                editorState != undefined
                  ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  : "";
              const replaceHtml = emailtext.replaceAll(
                "<p></p>",
                "<p>&nbsp;</p>"
              );
              const finalHtml = replaceHtml.replaceAll(
                "text-align:none",
                "text-align:center;"
              );
              this.setState({
                langEditorState: finalHtml,
                loadingEmailData: false,
              });
            }
          );
        } else {
          this.setState({
            emailData: res.data,
            loadingEmailData: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loadingEmailData: false,
          alertMsg: `${err.response?.data?.message}`,
          alertStatus: true,
          variant: "success",
        });
        console.log("error in Template Creation");
      });
  };

  /**
   * This function will send email
   * @param {string} languageId
   */
  handleSubmit = (languageId) => {
    this.setState({ loading: true });
    const projectid = this.props.match.params.id;
    SendEmail(projectid, languageId)
      .then((res) => {
        this.setState({ loading: false });
        scrollTopWindow();
        this.setState({
          alertMsg: `Email Sent Successfully`,
          alertStatus: true,
          variant: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        scrollTopWindow();
        console.log("Error Occured", err);
        this.setState({
          variant: "danger",
          alertMsg: `${err?.response?.data?.message}`,
          alertStatus: true,
        });
      });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  handleCommunicationSubmit = (fields, resetForm) => {
    this.setState({
      disablesavebtnme: true,
    });
    const projectid = this.props.match.params.id;
    // return;
    updateCommunicationDetail(fields, projectid)
      .then((res) => {
        scrollTopWindow();
        this.setState(
          {
            alertMsg: `Template Updated Successfully`,
            alertStatus: true,
            variant: "success",
            dirty: false,
            disablesavebtnme: false,
          },
          () => {
            this.getCommunicationData(
              projectid,
              fields.template_type,
              fields.language_id
            );
          }
        );
      })
      .catch((err) => {
        scrollTopWindow();
        this.setState({
          variant: "danger",
          alertMsg: `${err?.response?.data?.message}`,
          alertStatus: true,
          disablesavebtnme: false,
        });
      });
  };

  /**
   * This function fired when we select language
   * @param {object} option
   * @param {function} setFieldValue
   */

  selectTemplateType = (option, setFieldValue) => {
    setFieldValue("template_type", option.value);
    this.setState(
      {
        templateTypeValue: option,
      },
      () => {
        const id = this.props.match.params.id;
        this.getCommunicationData(
          id,
          this.state.templateTypeValue.value,
          this.state.languageId
        );
      }
    );
  };

  /**
   * This function fired when we select language
   * @param {object} option
   * @param {function} setFieldValue
   */
  handleLanguageSelection = (option, setFieldValue) => {
    setFieldValue("language_id", option.value);
    this.setState(
      {
        selectedLanguage: option,
        languageId: option.value,
      },
      () => {
        const projectId = this.props.match.params.id;
        this.getCommunicationData(
          projectId,
          this.state.templateTypeValue.value,
          this.state.languageId
        );
      }
    );
  };

  dirtyFlagChanged = (isDirty) => {
    this.props.setIsDirty(isDirty);
  };

  extractPlaceholders = (value) => {
    const placeholderRegex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    while ((match = placeholderRegex.exec(value)) !== null) {
      matches.push(match[1].trim());
    }
    return matches;
  };

  handlePopoverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    let { t } = this.props;
    const {
      editorState,
      langEditorState,
      projectLanguagesData,
      emailData,
      languageId,
      initialValuesTemplateType,
      emailBodyPlaceholders,
      emailSubjectPlaceholders,
      loadingEmailData,
      alertMsg,
      variant,
      alertStatus,
      templateTypeValue,
      disablesavebtnme,
      loading,
      anchorEl,
    } = this.state;
    const emailtext =
      editorState != undefined
        ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
        : "";
    const replaceHtml = emailtext.replaceAll("<p></p>", "<p>&nbsp;</p>");
    const finalHtml = replaceHtml.replaceAll(
      "text-align:none",
      "text-align:center;"
    );
    const templateOption = [
      {
        id: 1,
        value: "TEST_INVITATION_EMAIL",
        label: "Invitation Template",
        status: true,
      },
    ];

    let languageOption = projectLanguagesData;

    const canUserUpdateFields = !stringExistsInArray(
      this.props.statedata.isinternal
    );

    const isPopoverOpen = Boolean(anchorEl);

    Yup.addMethod(
      Yup.string,
      "validEmailBodyPlaceholders",
      function (placeholders, extractFunction, fieldType) {
        return this.test(
          "valid-placeholders",
          "Invalid placeholders in the subject",
          function (value) {
            if (!value) return true;

            const { path, createError } = this;
            const extractedPlaceholders = extractFunction(value);
            const invalidPlaceholders = extractedPlaceholders.filter(
              (ph) => !placeholders.includes(ph)
            );

            if (invalidPlaceholders.length > 0) {
              return createError({
                path,
                message: `${t(
                  "INVALID_PLACEHOLDERS"
                )} ${invalidPlaceholders.join(", ")}`,
              });
            }

            // Check for duplicates
            if (fieldType === "EMAIL") {
              const duplicates = extractedPlaceholders.filter(
                (item, index) => extractedPlaceholders.indexOf(item) !== index
              );

              if (duplicates.length > 0) {
                return createError({
                  path,
                  message: `${t("DUPLICATE_PLACEHOLDERS")} ${duplicates.join(
                    ", "
                  )}`,
                });
              }
            }

            return true;
          }
        );
      }
    );

    const commonPlaceholders =
      emailData?.placeholders &&
      emailData.placeholders.filter((placeholder) => {
        return placeholder.isDefaultPlaceholder;
      });
    const bodyPlaceholders =
      emailData?.placeholders &&
      emailData.placeholders.filter((placeholder) => {
        return !placeholder.isDefaultPlaceholder;
      });

    return (
      <div className="w-1200">
        <Formik
          enableReinitialize
          initialValues={{
            language_id: languageId,
            template_name: emailData.template_name || "",
            template_type: initialValuesTemplateType,
            description: emailData.description || "",
            template_content: langEditorState || "",
            email_subject: emailData.email_subject || "",
          }}
          validationSchema={Yup.object().shape({
            template_name: Yup.string().required(
              t("TEMPLATE_NAME_IS_REQUIRED")
            ),
            email_subject: Yup.string()
              .required(t("EMAIL_SUBJECT_IS_REQUIRED"))
              .validEmailBodyPlaceholders(
                emailSubjectPlaceholders,
                this.extractPlaceholders,
                "EMAIL"
              ),
            template_content: Yup.string().validEmailBodyPlaceholders(
              emailBodyPlaceholders,
              this.extractPlaceholders,
              "TEMPLATE"
            ),
          })}
          onSubmit={(fields, { resetForm }) => {
            this.handleCommunicationSubmit(fields, resetForm);
          }}
          render={(props) => {
            return (
              <Form
                className="agent-view"
                onChange={this.dirtyFlagChanged(props.dirty)}
              >
                <Grid container>
                  <Grid size={12}>
                    <AlertDismissible
                      msg={alertMsg}
                      variantType={variant}
                      show={alertStatus}
                      close={this.handleAlertClose}
                    ></AlertDismissible>
                  </Grid>
                </Grid>
                <CustomPrompt dirty={props.dirty} />
                <Card>
                  <CardContent>
                    {loadingEmailData ? (
                      <div className="custom-tab-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <Grid container className="email-temaplate-row">
                          <Grid size={1} />
                          <Grid className="email-temaplate-col" size={10}>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="language_id"
                              >
                                {t("language")}
                              </label>
                              <div className="col-sm-9">
                                <Select
                                  name="language_id"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={languageOption}
                                  isDisabled={canUserUpdateFields}
                                  value={
                                    props.values.language_id
                                      ? languageOption.find(
                                          (option) =>
                                            option.value ===
                                            props.values.language_id
                                        )
                                      : null
                                  }
                                  onChange={(option) =>
                                    this.handleLanguageSelection(
                                      option,
                                      props.setFieldValue
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="template_type"
                              >
                                {t("Template Type")}
                              </label>
                              <div className="col-sm-9">
                                <Select
                                  name="template_type"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  isDisabled={canUserUpdateFields}
                                  options={templateOption}
                                  onChange={(option) =>
                                    this.selectTemplateType(
                                      option,
                                      props.setFieldValue
                                    )
                                  }
                                  value={templateTypeValue}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label required"
                                htmlFor="template_name"
                              >
                                {t("Template Name")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="template_name"
                                  disabled={canUserUpdateFields}
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    "form-control" +
                                    (props.errors.template_name &&
                                    props.touched.template_name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {props.errors.template_name &&
                                  props.touched.template_name && (
                                    <div className="input-feedback add-tenant-validation">
                                      {props.errors.template_name}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label"
                                htmlFor="description"
                              >
                                {t("Description")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="description"
                                  disabled={canUserUpdateFields}
                                  autoComplete="off"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-9 d-flex align-items-center">
                                <IconButton
                                  aria-describedby={
                                    isPopoverOpen ? "click-popover" : undefined
                                  }
                                  onClick={this.handlePopoverClick}
                                  size="small"
                                >
                                  <InfoIcon color="primary" />
                                </IconButton>
                                <div className="field-help-text">
                                  {t("EMAIL_HELPER_TEXT")}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                className="col-sm-3 col-form-label required"
                                htmlFor="email_subject"
                              >
                                {t("Email Subject")}
                              </label>
                              <div className="col-sm-9">
                                <Field
                                  name="email_subject"
                                  disabled={canUserUpdateFields}
                                  autoComplete="off"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (props.errors.email_subject &&
                                    props.touched.email_subject
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {props.errors.email_subject &&
                                  props.touched.email_subject && (
                                    <div className="input-feedback add-tenant-validation">
                                      {props.errors.email_subject}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">
                                {t("EMAIL_TEMPLATE")}
                              </label>
                              <Editor
                                readOnly={canUserUpdateFields}
                                editorState={editorState}
                                wrapperClassName="email-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={(editorState) => {
                                  const finalTemplatestate =
                                    editorState != undefined
                                      ? draftToHtml(
                                          convertToRaw(
                                            editorState.getCurrentContent()
                                          )
                                        )
                                      : "";
                                  this.setState({
                                    editorState,
                                  });
                                  props.setFieldValue(
                                    "template_content",
                                    finalTemplatestate
                                  );
                                }}
                                handlePastedText={() => false}
                              />
                            </div>
                            {props.errors.template_content && (
                              <div className="input-feedback">
                                {props.errors.template_content}
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid size={1} />
                          <Grid size={10}>
                            <div className="form-group float-left">
                              <Button
                                variant="primary"
                                type="button"
                                className="rounded-0 mr-4 save-btn"
                                onClick={() =>
                                  this.handleSubmit(props.values.language_id)
                                }
                              >
                                {loading
                                  ? `${t("Send Test Email")}...`
                                  : t("Send Test Email")}
                              </Button>
                            </div>

                            <div className="form-group float-right">
                              <Button
                                variant="primary"
                                type="submit"
                                className="rounded-0  save-btn button_with_loader"
                                disabled={
                                  canUserUpdateFields
                                    ? canUserUpdateFields
                                    : disablesavebtnme
                                }
                              >
                                {disablesavebtnme ? (
                                  <>
                                    {t("Save")}...
                                    <div className="custom-loader linesLoader loader_inner_button">
                                      <Spinner color="#ffffff" size={"19px"} />
                                    </div>
                                  </>
                                ) : (
                                  <span>{t("Save")}</span>
                                )}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Form>
            );
          }}
        />
        <EmailHelperBox
          isPopoverOpen={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
          commonPlaceholders={commonPlaceholders}
          bodyPlaceholders={bodyPlaceholders}
        />
      </div>
    );
  }
}
export default withTranslation()(Email);
