import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    boxSizing: "border-box",
    padding: "16px",
    width: "100%",
    boxShadow: "0px 0px 30px -11px rgba(128,128,128,1)",
    display: "flex",
    flexDirection: "column",
  },
  documentContent: {
    backgroundColor: "#f2f3f8",
  },
  content: {
    padding: "8px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px",
  },
  logoImage: {
    maxHeight: "36px",
    maxWidth: "155px",
    objectFit: "contain",
  },
  riskLevelIcon: {
    maxHeight: "20px",
    maxWidth: "30px",
    objectFit: "contain",
  },
  headerText: {
    fontSize: "14pt",
    fontWeight: "500",
    color: "#000000",
  },
  pd: {
    padding: "8px 10px",
    fontSize: "16pt",
  },

  table: {
    width: "100%",
    display: "table",
  },
  tableRow: {
    display: "table-row",
    flexDirection: "row",
    width: "100%",
  },
  tableRowHeader: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  tableCol100: {
    display: "table-cell",
    width: "100%",
    padding: "10px",
    backgroundColor: "#e2e6ec",
    fontSize: "20pt",
  },
  tableCol100WithOutPadding: {
    display: "table-cell",
    width: "100%",
    backgroundColor: "#c9cbce",
    fontSize: "20pt",
  },
  tableCol: {
    display: "flex",
    flexDirection: "row",
    width: "35%",
    padding: "6px",
  },
  tableCol33: {
    display: "flex",
    flexDirection: "row",
    width: "33.3%",
    padding: "6px",
  },
  tableCol66: {
    display: "table-cell",
    width: "66.6%",
    padding: "6px",
  },
  tableCol50: {
    display: "table-cell",
    width: "50%",
    padding: "8px",
  },
  tableCol40: {
    display: "table-cell",
    width: "40%",
    padding: "8px",
  },
  tableCol70: {
    display: "table-cell",
    width: "70%",
    padding: "8px",
  },
  tableCol75: {
    display: "table-cell",
    width: "75%",
    padding: "8px",
  },
  tableCol80: {
    display: "table-cell",
    width: "80%",
    padding: "4px",
  },
  tableCol85: {
    display: "table-cell",
    width: "85%",
    padding: "4px",
  },
  tableCol8: {
    display: "table-cell",
    width: "40px",
    padding: "8px",
  },
  tableCol10: {
    display: "table-cell",
    width: "10%",
    padding: "8px",
  },
  tableCol20: {
    display: "table-cell",
    width: "20%",
    padding: "4px",
  },

  tableCol15: {
    display: "table-cell",
    width: "15%",
    padding: "4px",
  },
  tableCol30: {
    display: "table-cell",
    width: "30%",
    padding: "8px",
  },
  tableCol90: {
    display: "table-cell",
    width: "90%",
    padding: "8px",
  },
  infoBlue: {
    backgroundColor: "#206AD5",
    color: "##FFFFFF",
    borderTopLeftRadius: "9pt",
    borderTopRightRadius: "9pt",
    paddingTop: "4px",
    paddingBottom: "4px",
    justifyContent: "space-between",
  },

  labelText: {
    color: "#898989",
    fontWeight: "400",
    fontSize: "11pt",
    marginRight: "2pt"
  },
  labelWidth: {
    width: "66pt",
  },
  projectNameWidth: {
    width: "278pt",
  },
  rowContent: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "row",
    boxSizing: "border-box",
    flexWrap: "wrap",
    columnGap: 1,
  },
  interviewHeading: {
    color: "#555",
    fontSize: "13pt",
    fontWeight: "700",
  },
  borderB: {
    borderBottom: "1pt solid #ccc",
  },
  borderL: {
    borderLeft: "1pt solid #ccc",
  },
  borderR: {
    borderRight: "1pt solid #ccc",
  },
  borderT: {
    borderTop: "1pt solid #ccc",
  },
  borderA: {
    border: "1pt solid #ccc",
    marginBottom: "5px",
  },
  borderNone: {
    border: "none",
  },
  MainHead: {
    width: "100%",
    display: "block",
    textAlign: "center",
    marginBottom: "2px",
  },
  mainText: {
    fontSize: "18pt",
    color: "#555555",
    padding: "10px 0",
    fontWeight: "bold",
  },
  nopaddding: {
    padding: "0 !important",
  },
  font14: {
    fontSize: "10.50pt",
  },
  font16: {
    fontSize: "11pt",
  },
  font17: {
    fontSize: "12.75pt",
  },
  overAllEvaluationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    fontWeight: "700",
    border: "1px solid #c6b7b6",
  },
  overAllRiskLabel: {
    color: "#0F0F0F",
    fontSize: "12pt",
    // lineHeight: "14px",
    fontWeight: "700",
    margin: "3px 0px",
  },
  overAllRisk: {
    color: "#db0004",
    fontSize: "12pt",
    fontWeight: "700",
    textTransform: "uppercase",
    marginLeft: "5px",
  },

  footerMainContainer: {
    margin: "1px",
  },
  footerFirstContainer: {
    margin: "1px",
  },
  footerSecondContainer: {
    margin: "1px",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "8px",
    marginRight: "8px",
  },
  footerHeading: {
    fontSize: "11pt",
    fontWeight: "400",
    textAlign: "left",
    padding: "8px",
    backgroundColor: "#EEEEEE",
    color: "#000000",
  },
  footerText: {
    fontSize: "9pt",
    margin: "5px",
    color: "#626366",
  },
  lastFooterText: {
    fontSize: "9pt",
    margin: "5px",
    color: "#081829",
  },
  resultDetail: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  questionTC: {
    fontSize: "10pt",
    margin: "5px",
    color: "#626366",
    fontWeight: "400",
    // lineHeight: "12em",
  },
  questionName: {
    fontSize: "12px",
    color: "#626366",
    fontWeight: "bold",
    margin: "3px",
  },
  iconWidth: {
    width: "8px",
    height: "8px",
  },
  tableIconWidth: {
    width: "10px",
    height: "10px",
  },
  lowRiskIcon: {
    backgroundColor: "#008000",
  },
  averageRiskIcon: {
    backgroundColor: "#92d050",
  },
  potentialRiskIcon: {
    backgroundColor: "#ffca00",
  },
  highRiskIcon: {
    backgroundColor: "#ff0000",
  },
  nonResponsiveParticipant: {
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#000000",
  },
  counterMeasureIcon: {
    backgroundColor: "#6758f3",
  },
  admissionIcon: {
    backgroundColor: "#1665d8",
  },
  notCompletedIcon: {
    backgroundColor: "#bcbcbc",
  },
  disconnectedIcon: {
    backgroundColor: "#6c6b6b",
  },
  participantDetailsText: {
    color: "#000000",
    fontWeight: "500",
    fontSize: "11pt",
    justifyContent: "flex-start"
  },

  questionLabel: {
    justifyContent: "flex-start",
    marginLeft: "6px",
  },
  resultLabel: {
    justifyContent: "flex-start",
    width: "64px",
    margin: "0px 6px",
  },
  questionTableContainer: {
    color: "#000000",
    borderRadius: 4,
    justifyContent: "space-between",
  },
  resultColumnColor: {
    color: "#FFFFFF",
  },
  pageContainer: {
    flex: 1,
  },
  questionNumber: {
    margin: "0px 6px",
  },
  iconMargin: {
    marginTop: "6px",
  },
});
