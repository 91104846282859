import axios from "axios";
import api from "./api";

export const getParticipantList = (id, filter) => {
  return api.post(`project-service/v1/project/${id}/participants`, filter);
  //   return  api.post(`project-service/v1/project/${id}/participants`,{
  //     params: {
  //         pageNumber: no,
  //         count: count
  //     }})
};

export const getParticipant = (id) => {
  return api.get(`participant-service/v1/participants/${id}`);
};

export const getSendInvitation = (id, type) => {
  return api.get(
    `participant-service/v1/reinvite/${id}?invitation_type=${type}`
  );
};

export const getRefreshAccessCode = (id) => {
  return api.get(`participant-service/v1/accesscode/refresh/${id}`);
};

export const addParticipant = (fields) => {
  const isTestparticipant = fields.participants[0].is_test_participant;
  return api.post(
    `participant-service/v1/participants/?is_test_participant=${isTestparticipant}`,
    fields
  );
};

export const updateParticipant = (fields, id) => {
  const isTestparticipant = fields.is_test_participant;
  return api.put(
    `participant-service/v1/participants/${id}?is_test_participant=${isTestparticipant}`,
    fields
  );
};

export const uploadParticipants = (fields) => {
  return api.post(
    `participant-service/v1/participants/uploadParticipants`,
    fields
  );
};
export const downloadParticipantTemplate = () => {
  return api.get(`participant-service/v1/participants/downloadTemplate`, {
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    responseType: "arraybuffer",
  });
};

export const removeParticipants = (fields) => {
  return api({
    method: "delete",
    url: "participant-service/v1/participants/deleteParticipants",
    data: fields,
  });
};

export const searchParticipants = (
  text,
  locationId,
  status,
  projectId,
  no,
  count
) => {
  return api.get(`participant-service/v1/participants/search`, {
    params: {
      text: text,
      locationId: locationId,
      projectId: projectId,
      status: status,
      pageNumber: no,
      count: count,
    },
  });
};

export const SendEmail = (projectId, languageId) => {
  return api.get(`participant-service/v1/test/email`, {
    params: {
      projectId: projectId,
      templateType: "TEST_INVITATION_EMAIL",
      language_id: languageId,
    },
  });
};

export const SendSMS = (projectId, languageId) => {
  return api.get(`participant-service/v1/test/email`, {
    params: {
      projectId: projectId,
      templateType: "TEST_INVITATION_SMS",
      language_id: languageId,
    },
  });
};

export const sendInvitationEmail = (fields) => {
  return api.post(`participant-service/v1/bulk/invite`, fields);
};
export const verifySendInvitations = (fields) => {
  return api.post(`participant-service/v1/bulk/verify`, fields);
};

export const verifyNPParticipantStatus = (fields) => {
  return api.post(
    `participant-service/v1/participants/non-responsive/validate`,
    fields
  );
};

export const updateNPParticipantStatus = (fields) => {
  return api.post(
    `participant-service/v1/participants/non-responsive/marking`,
    fields
  );
};

export const exportParticipants = (fields) => {
  return api.post(
    `participant-service/v1/participants/exportParticipants`,
    fields,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getParticipantDetail = (participantId) => {
  return api.get(`participant-service/v1/participants/${participantId}/trail`);
};

export const getResultAuditLogs = (participantId) => {
  return api.get(
    `participant-service/v1/participants/${participantId}/trail/backenddata`
  );
};

export const getMobileAppData = (participantId) => {
  return api.get(
    `participant-service/v1/participants/trail?participantId=${participantId}`
  );
};

export const isEmailTemplateConfigured = (projectId) => {
  return api.get(
    `project-service/v1/project/communication-template/${projectId}`
  );
};

export const selectedParticipantHide = (fields) => {
  return api.post(
    `participant-service/v1/participants/hide/participants`,
    fields
  );
};
export const selectedNPParticipant = (participant) => {
  return api.post("participant-service/v1/participants/np-action", participant);
};
