import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import dataoptions from "../../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../../assets/icon/dataoptionsw.png";
import delete_icon from "../../../../assets/icon/delete.png";
import delete_iconw from "../../../../assets/icon/delete_blue.png";
import hide_icon from "../../../../assets/icon/hide-blue.png";
import hide_iconw from "../../../../assets/icon/hide-white.png";
import view_icon from "../../../../assets/icon/view-blue.png";
import view_iconw from "../../../../assets/icon/view-white.png";
import SearchAccessCode from "./SearchAccessCode";

const FiltersTable = ({
  handleSearchText,
  handleStatusChange,
  handleTypeChange,
  handleShowTestAccessCode,
  showTestAccessCode,
  IsDisabled,
  handleBurnSelectedCode,
}) => {
  console.log("IsDisabled", IsDisabled);
  const { t } = useTranslation();
  const themeColor = useSelector((state) => state.theme.themeColor);

  const statusOptions = [
    {
      value: "Available",
      label: t("Available"),
    },
    {
      value: "Assigned",
      label: t("Assigned"),
    },
    {
      value: "Burned",
      label: t("Burned"),
    },
  ];

  const typeOptions = [
    {
      value: "Primary",
      label: t("Primary"),
    },
    {
      value: "Backup",
      label: t("Backup"),
    },
  ];

  const selectStatus = (option) => {
    const value = option ? option.value : option;
    handleStatusChange(value);
  };

  const selectType = (option) => {
    const value = option ? option.value : option;
    handleTypeChange(value);
  };

  return (
    <>
      <Grid container className="filter-access-code-page participants-page">
        <Grid size={12}>
          <div className="d-flex particiant-controls">
            <div>
              <Card>
                <CardHeader title={t("Find Access Code")}></CardHeader>
                <CardContent>
                  <SearchAccessCode
                    handleSearchAccessCode={(value) => handleSearchText(value)}
                  />
                </CardContent>
              </Card>
            </div>

            <div>
              <Card className="zindex99">
                <CardHeader title={t("Data Filter")}></CardHeader>
                <CardContent className="resultsearchindex">
                  <Grid container>
                    <Select
                      name="status"
                      className="col-lg-6 select-style"
                      classNamePrefix="select"
                      options={statusOptions}
                      isClearable={true}
                      placeholder={t("Select Status")}
                      onChange={selectStatus}
                    />

                    <Select
                      name="type"
                      className="col-lg-6 select-style"
                      classNamePrefix="select"
                      options={typeOptions}
                      isClearable={true}
                      placeholder={t("Select Type")}
                      onChange={selectType}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </div>

            <div>
              <Card className="card">
                <CardHeader title={t("Data Options")}></CardHeader>
                <CardContent className="resultsearchindex">
                  <div className="d-flex tables-control-menu">
                    <div className="position-relative">
                      <div
                        className="data-option-container"
                        id="handleDataOption"
                      >
                        <div className="data-control">
                          <div className="d-flex align-items-center download-btn">
                            <div>
                              <img
                                src={
                                  themeColor === "light"
                                    ? dataoptions
                                    : dataoptionsw
                                }
                              />
                            </div>
                            <span className="text-uppercase">
                              {t("Data Options")}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <nav className="data-menu nav">
                            <div className="nav-item">
                              <div className="d-flex align-items-center download-btn data-item">
                                <div>
                                  <img
                                    src={
                                      themeColor === "light"
                                        ? dataoptions
                                        : dataoptionsw
                                    }
                                  />
                                </div>
                                <span className="text-uppercase">
                                  {t("Data Options")}
                                </span>
                              </div>
                            </div>
                            <div className="nav-item">
                              <div
                                className={`d-flex align-items-center download-btn data-item ${
                                  IsDisabled
                                    ? "event-disabled"
                                    : "event-not-disabled"
                                }`}
                                onClick={() => handleBurnSelectedCode()}
                              >
                                <div>
                                  <img
                                    src={
                                      themeColor === "light"
                                        ? delete_iconw
                                        : delete_icon
                                    }
                                  />
                                </div>
                                <span className="text-uppercase">
                                  {t("Burn Code")}
                                </span>
                              </div>
                            </div>
                            <div className="nav-item">
                              {!showTestAccessCode ? (
                                <div
                                  className="d-flex align-items-center export-btn data-item"
                                  onClick={() =>
                                    handleShowTestAccessCode("SHOW")
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        themeColor === "light"
                                          ? view_icon
                                          : view_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase">
                                    {t("Show Only Test Access Code")}
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="d-flex align-items-center export-btn data-item"
                                  onClick={() =>
                                    handleShowTestAccessCode("HIDE")
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        themeColor === "light"
                                          ? hide_icon
                                          : hide_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase">
                                    {t("Hide Test Access Code")}
                                  </span>
                                </div>
                              )}
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersTable;
